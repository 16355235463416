@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Inter:wght@300;400;500;600&family=Dancing+Script:wght@400;700&family=Caveat:wght@400;700&family=Lilita+One&display=swap');

body {
  margin: 0;
  font-family: 'Gelasio', serif;
  color: #555555;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.christmas-card {
  font-family: 'ChristmasCard';
}

@font-face {
  font-family: 'ChristmasCard';
  src: local('ChristmasCard'), url(./assets/fonts/christmas.TTF) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mail-border {
    border: 3px solid transparent; /* Set the border width */
    background: linear-gradient(to bottom, #EC4899, #EF4444); /* Tailwind pink-500 to red-500 */
    background-clip: padding-box, border-box;
    background-origin: border-box;  
}

@layer utilities {
  .perspective-\[2000px\] {
    perspective: 2000px;
  }
  
  .transform-style-preserve-3d {
    transform-style: preserve-3d;
  }
  
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
  
  .backface-hidden {
    backface-visibility: hidden;
  }
}

.handwritten {
  font-family: 'Dancing Script', cursive;
}

.handwritten-alt {
  font-family: 'Caveat', cursive;
}

.bubble {
  font-family: 'Fredoka', sans-serif;
}

.chunky {
  font-family: 'Lilita One', cursive;
  -webkit-text-stroke: 1px #9da7a9; /* gray-400 */
  color: transparent;
  text-shadow: none;
}

.chunky-dark {
  font-family: 'Lilita One', cursive;
  -webkit-text-stroke: 1px #a28883;
  color: transparent;
  text-shadow: none;
}

@layer components {
  .side-left {
    transform: skewY(45deg);
    transition: transform 500ms;
  }
  
  .group:hover .side-left {
    transform: skewY(33deg);
  }

  .side-top {
    transform: skewX(45deg);
    transition: transform 500ms;
  }
  
  .group:hover .side-top {
    transform: skewX(58deg);
  }
}
